<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Boletos</h4>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h5 class="">Claro SA</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h6 class="text-primary mt-3 mb-0">Contrato 31244-2</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <h6 class="mt-2">Comprovante de pagamento</h6>
              <div class="row mt-4 pb-4">
                <div class="col-xs-8 col-12 col-lg-5" style="">
                  <p class="text-info mt-3 mb-1">Referência:</p>
                  <p class="codebar">34196012340120105432102101 020103613131545000</p>
                  <p class="text-info mt-3 mb-1">Enviar comprovante de pagamento (formatos: jpg, jpeg, png, gif, pdf) </p>
                </div>
                <div class="col-xs-8 col-12 pb-3 pt-2 col-lg-3" style="">
                  <label for="inputState" class="small">Tipo de pagamento:</label>
                  <select id="inputState" class="form-control form-control-sm">
                    <option selected="" value="Boleto">Boleto</option>
                    <option value="Pix">Pix</option>
                  </select>
                </div>
                <div class="col-xs-8 col-12 pb-3 pt-2 col-lg-4" style="">
                  <div class="form-group small">
                    <label>Comprovante:</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="CustomFile" required="">
                      <label class="custom-file-label" for="CustomFile">Seu arquivo</label>
                      <div class="invalid-feedback">Erro. Formato inválido.</div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-8 col-12 pb-3 pt-2 col-lg-12 text-center" style=""><a class="btn btn-outline-primary mr-1 mb-1 mt-3" href="boletos-vivo.html">Enviar comprovante</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="">
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
} from "@/utils/helpers";
//import ContentMenu from '../components/ContentMenu.vue';
export default {
  mixins: [validationMixin],
  name: "Comprovante",
  components: {},
  validations: {
    form: {
      file: { required },
      Reference: { required },
      OwnerID: { required },
      TypeProofPaymentID: { required },
      selectedTypeProofPayment: { required },
    },
  },
  data() {
    return {
      links: [],
      linhadigitavel: "",
      file: null,
      selectedTypeProofPayment: null,
      typesProofPayment: [],
      debtorProofPaymentId: null,
    };
  },
  async created() {
    this.user.Menus.forEach((menuId) => {
      switch (parseInt(menuId)) {
        case 2:
          this.links.push({
            icon: "",
            text: "Contratos Negociáveis",
            route: "/dashboard/minhas-dividas",
          });
          break;
        case 1:
          this.links.push({
            icon: "",
            text: "Boletos Ativos",
            route: "meusBoletos",
          });
          this.links.push({
            icon: "",
            text: "Comprovante",
            route: "comprovante",
            params: { linhadigitavel: this.linhadigitavel },
          });
          break;
        case 3:
          this.links.push({
            icon: "",
            text: "Acordos",
            route: "/dashboard/meus-acordos",
          });
          break;
      }
    });
    this.linhadigitavel = this.$route.params.linhadigitavel;
    this.debtorProofPaymentId = this.$route.params.debtorProofPaymentID;

    this.buscarTiposPagamento();
  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,
    ...mapActions("meusBoletos", [
      "ActionFindBoletoExpress",
      "ActionSendEmailOrSmsText",
      "ActionSendAccessLog",
    ]),
    ...mapActions("comprovante", [
      "ActionUploadProofPayment",
      "ActionListTypeProofPayment",
    ]),
    voltar() {
      this.$router.push({ name: "dashboard" });
    },

    async enviarComprovante(linhaDigitavel) {
      let dataForm = new FormData();

      for (let file of this.$refs.file.files) {
        dataForm.append(`File`, file);
      }

      if (this.selectedTypeProofPayment == "Boleto") {
        this.selectedTypeProofPayment = 2;
      } else {
        this.selectedTypeProofPayment = 1;
      }

      dataForm.append("Reference", this.linhadigitavel);
      dataForm.append("OwnerID", 2);
      dataForm.append("TypeProofPaymentID", this.selectedTypeProofPayment);
      if(this.debtorProofPaymentId) {
        dataForm.append("DebtorProofPaymentID", this.debtorProofPaymentId);
      } else {
        dataForm.append("DebtorProofPaymentID", 0);
      }
      try {
        var resp = await this.ActionUploadProofPayment(dataForm);

        if (!resp.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            message: "Comprovante enviado com sucesso",
          });

          this.$router.push({
            name: "dashboard",
            params: { linhadigitavel: linhaDigitavel },
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "ocorreu um erro",
            message: resp.data.message,
          });
        }
      } catch (error) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: error.data.message,
        });
        this.loading = false;
      }
    },

    async buscarTiposPagamento() {
      var resp = await this.ActionListTypeProofPayment();
      this.typesProofPayment = resp;
      this.selectedTypeProofPayment = resp[0];
    },
  },
};
</script>