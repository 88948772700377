<template>
  <div id="inside">
    <div class="container">
      <div v-if="!loading"
          class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
              <button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-user-o"></i>cadastro</button
              ><button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-key"></i>código de acesso</button
              >
          </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Contratos em aberto</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <button class="btn btn-primary mt-3" @click="voltar()" v-if="!loading">Voltar</button>
        </div>
      </div>
    </div>
    <div class="container" style="margin-bottom: 100px;">
      <ProgressLoading v-bind:loading="loading" />
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-5 col-lg-12" v-if="!loading && !hasDebts">
            <h4>Você não possui contratos a serem negociados.</h4>
        </div>
      <div class="row" style="" v-if="hasDebts">
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-12" v-for="(value, key, index) in debts" :key="`${key}-${index}`">
          <div class="card"  v-for="(value1, key1, index1) in value" :key="`${key1}-${index1}`">
            <div class="card-header" style="">
              <div class="row">
                <div class="col-12">
                  <h5>{{ value1.Produto }}</h5>
                </div>
              </div>
            </div>
            <div class="card-body pb-2" >
              <div class="row mb-3">
                <div class="col-md-9 col-10">
                  <h6 class="mb-3">{{ key + 1 }} - Contrato {{ value1.NumeroTitulo.substring(0, 3) + 'XXXXXX' +  value1.NumeroTitulo.substr(-3) }}</h6>
                </div>
                <div class="col-md-3 col-10" v-if="compareDates(value1.Parcelas.ParcelaDivida[0].Vencimento)">
                  <h6 class="mb-3">Status:&nbsp;<span class="badge badge-pill badge-danger">Vencido</span></h6>
                </div>
                <div class="col-md-3 col-10" v-else>
                  <h6 class="mb-3">Status:&nbsp;<span class="badge badge-pill badge-warning">Pendente</span></h6>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-5 col-lg-5">
                  <p class="mb-1">Valor original</p>
                  <h6 class="text-danger">{{ sumByContract(value1) }}</h6>
                </div>
                <div class="col-7 col-lg-4" >
                  <p class="mb-1">Vencimento original:</p>
                  <h6 class="">{{ value1.Parcelas.ParcelaDivida[0].Vencimento }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-12 mx-0 px-0" v-if="value1">
                  <div class="table-responsive" :style="!value1.expanded.isExp ? cssHeight(value1) : ''">
                    <table class="table table-hover table-striped">
                      <thead class="small"> 
                        <tr>
                          <th scope="col">Contrato | Parcela</th>
                          <th scope="col">Data vencimento</th>
                          <th scope="col">Valor débito</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody class="small">
                        <tr v-for="(installments, key1, index1) in value1.Parcelas.ParcelaDivida" :key="`${key1}-${index1}`">
                          <th scope="row">{{ value1.NumeroTitulo.substring(0, 3) + 'XXXXXX' +  value1.NumeroTitulo.substr(-3) }} - {{ installments.CodigoParcela }}</th>
                          <td>{{ installments.Vencimento }}</td>
                          <td>R$ {{ installments.Valor }}</td>
                          <td v-if="compareDates(installments.Vencimento)" class="text-danger">Vencido</td>
                          <td v-else class="text-warning">Pendente</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-3" style="" v-if="value1">
              <button class="btn btn-lg btn-primary" :style="!value1.expanded.showExp ? '' : 'display: none;'" @click="expandir(value1)">{{ value1.expanded.value }}</button>
              <button
                  @click="propostas(value)"
                  class="btn btn-outline-primary btn-sm"
                  >Simulador</button
                >
              </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
// import ContentMenu from '../components/ContentMenu.vue';
import ProgressLoading from '../../../modules/auth/components/ProgressLoading.vue';
export default {
    name: "MeusContratos",

    components: {
      // ContentMenu,
      ProgressLoading
    },
    data () {
        return {
          loading : false,
          loadDividas: false,
          dividas: null,
          contracts: {},
          agreements: [],
          vencimentoOriginal: null,
          hasDebts: false,
          debts: []
        }
    },
    async created() {
      this.loading = true
      try {
        const respContract =  await this.ActionGetDebtsClaro();
        this.contracts = respContract || {};
        
  

        this.debts = this.contracts.filter(value => { return value.debts.length > 0 }).map((item => {
              console.log('item: ' + JSON.stringify(item))
          
          return item.debts.map((value => {
            this.hasDebts = true;
            return {
              ...value,
              expanded: { isExp: false, value: "Expandir", showExp: value.Parcelas.ParcelaDivida.length <= 5 }
            } 
          }))
        }));
              console.log('this.debts: ' + JSON.stringify(this.debts))
        this.loading = false
      } catch {
        this.$router.push({
          name: "dashboard",
        });
      }

    },
    methods: {
        formatValue,
        formatData,
        ...mapActions("meusContratos", ["ActionGetDebtsClaro"]),
        // ...mapActions('meusContratos', ['ActionFindDividas']),
        compareDates (date) { 
          let parts = date.split('/') 
          let today = new Date()    
          
          date = new Date(parts[2], parts[1] - 1, parts[0])
          return today > date;
          
        },

        sumByContract(value) {
          let total = 0
          value.Parcelas.ParcelaDivida.map((parcela) => {
            total += parseFloat(parcela.Valor.replace('.', '').replace(',', '.'), 10)
          })
          return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace("-","")
        },

        verBoletos() {
          this.$router.push({
            name: "meusBoletos",
          });
        },

        contractsPending() {
          return;
        },

        cadastro() {
          this.$router.push({
            name: "cadastro",
          });
        },

        voltar() {
             this.$router.push({ name: 'dashboard' });
        },

        cssHeight(value) {
          if (value.Parcelas.ParcelaDivida.length > 5) {
            return 'height: 18.2rem; overflow-y: auto;'
          } else {
            // value.expanded.showExp = true
            return `height: ${value.Parcelas.ParcelaDivida.length * 2.2 + 4.5}rem; overflow-y: auto;`
          }
        },  

        async expandir(value) {
          value.expanded.isExp = !value.expanded.isExp;
          value.expanded.value =
            value.expanded.value === "Expandir"
              ? "Esconder"
              : "Expandir";
        },

        async propostas(value) {
          localStorage.setItem("contractSelected", JSON.stringify(value));
          // const contracts = Array.isArray(this.contracts) ? this.contracts : [this.contracts];
          this.$router.push({
            name: "propostas",
          });
        },
      },
};
</script>