<template>
  <div id="inside">
    <div class="container">
      <div v-if="!loadBoletos && largura > 500" class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          " style="">
          <div class="btn-group btn-group-sm">
            <button class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              " @click="contractsPending()">
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              " @click="verBoletos()">
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
            <button class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                " @click="cadastro()"><i class="fa fa-fw fa-user-o"></i>cadastro</button><button class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                " @click="cadastro()"><i class="fa fa-fw fa-key"></i>código de acesso</button>
          </div>
          </div>
      <div class="row" v-if="!loadBoletos && largura < 500">
        <div class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          " style="">
          <div class="btn-group btn-group-sm">
            <button class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              " @click="contractsPending()">
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              " @click="verBoletos()">
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
            <button class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                " @click="cadastro()"><i class="fa fa-fw fa-user-o"></i>cadastro</button><button class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                " @click="cadastro()"><i class="fa fa-fw fa-key"></i>código de acesso</button>
          </div>
          </div>
      </div>
      <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="margin-left: -15px;">
        <h4 class="mb-5 text-primary">Meus Acordos</h4>
      </div>
      <div class="row" v-if="!loadBoletos">
      <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
        <button class="btn btn-primary mt-3" @click="voltar()">Voltar</button>
      </div>
      </div>
    </div>
    <div class="container">
      <ProgressLoading v-bind:loading="loadBoletos" /> 

    </div> 
    <div class="container pt-5" v-if="!hasBills && !loadBoletos && !agreementsOne">
      <h4>Você não possui boletos em aberto.</h4> 
    </div>
      <div class="container">
    <div class="row">
      <!-- <pre>{{ agreementsOne  }}</pre> -->
      <div v-if="hasBills && !loadBoletos" :style="largura < 500 ? '' : 'display: flex;' ">
        <div  v-for="(value1, key1, index1) in contracts"
          :key="`${key1}-${index1}`" style="display: flex;">
        <div v-bind:class="[ value1.agreements.length > 1 ? 'col-lg-5' : 'col-lg-10' ]" v-for="(value, key, index) in value1.agreements"
          :key="`${key}-${index}`">
          <div class="card" v-for="(installments, key, index) in value.ParcelasAcordo.ParcelaAcordo.slice(0,1)"
            :key="`${key}-${index}`" >
            <div class="card-body pb-2 border-top-0 py-2" >
              <div class="row"> 
                <div class="col-lg-12 col-12">
                  <h5 class="">{{ value.Produto }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <h6 class="text-primary mt-4 mb-0">Contrato {{ value.NumeroTitulo.substring(0, 3) + 'XXXXXX' +  value.NumeroTitulo.substr(-3) }}</h6>
                </div>
                <div class="col-lg-6 col-6 mt-3">
                  <p class="small mb-1 d-inline-flex">Status:</p>
                  <p class="small d-inline-flex mb-1 ml-2 text-info">{{ installments.StatusParcelaAcordo }}</p><br>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row mt-4 pb-4">
                <div class="col-xs-8 col-3 col-lg-3" style="">
                  <p class="mb-1 small">Parcelas:</p>
                  <h6 class="d-inline-flex bg-primary text-light rounded small py-1 px-2">{{ key + 1 }}/{{ value.ParcelasAcordo.ParcelaAcordo.length }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-4" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <h6 class="">{{ installments.DataVencimento }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-5 col-lg-5" style="">
                  <p class="mb-1 small">Valor negociado:</p>
                  <h6 class="small">R$ {{ sumByContract(value) }}</h6>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-12 pt-2" style="">
                  <button class="btn btn-primary mt-3" @click="verBoletoDetalhado(value, value1)">Ver boletos</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>

        <div v-if="hasBills && !loadBoletos" :style="largura < 500 ? '' : 'display: flex;' ">
        <div  v-for="(value, key, index) in contractClosed"
          :key="`Cetelem-${key}-${index}`" style="display: flex;">
          <div v-bind:class="[ value.installments.length > 1 ? 'col-lg-10' : 'col-lg-10' ]" >
          <div class="card" v-for="(installments, key, index) in value.installments"
            :key="`${key}-${index}`" >
            <div class="card-body pb-2 border-top-0 py-2" >
              <div class="row"> 
                <div class="col-lg-12 col-12">
                  <h5 class="">{{ value.Produto }}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <h6 class="text-primary mt-4 mb-0">Contrato {{ value.contractNumber.substring(0, 3) + 'XXXXXX' +  value.contractNumber.substr(-3) }}</h6>
                </div>
                <div class="col-lg-6 col-6 mt-3">
                  <p class="small mb-1 d-inline-flex">Status:</p>
                  <p class="small d-inline-flex mb-1 ml-2 text-info">{{ "EM ABERTO" }}</p><br>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div class="row mt-4 pb-4">
                <div class="col-xs-8 col-3 col-lg-3" style="">
                  <p class="mb-1 small">Parcelas:</p>
                  <h6 class="d-inline-flex bg-primary text-light rounded small py-1 px-2">{{ key + 1 }}/{{ value.installments.length }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-4 col-lg-4" style="">
                  <p class="mb-1 small">Vencimento:</p>
                  <h6 class="">{{ installments.dueDate }}</h6>
                </div>
                <div class="col-xs-8 pl-0 col-5 col-lg-5" style="">
                  <p class="mb-1 small">Valor negociado:</p>
                  <h6 class="small">R$ {{ value.updatedValue }}</h6>
                </div>
                <div class="col-xs-8 col-12 d-flex flex-column col-lg-12 pt-2" style="">
                  <button class="btn btn-primary mt-3" @click="verBoletoDetalhado(value, null)">Ver boletos</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>

        <div v-if="!loadBoletos" :style="largura < 500 ? 'margin: auto;' : 'display: flex;' ">
          <div v-for="(value1, key1, index1) in agreementsOne" :key="`${key1}-${index1}`">
            
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body pb-2 border-top-0 py-2">
                <div class="row">
                  <div class="col-lg-12 col-12">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <h6 class="text-primary mt-4 mb-0">Contrato ONE-{{ value1.contrato.substring(0, 3) + 'XXXXXX' +  value1.contrato.substr(-3) }}</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-12">
                  </div>
                </div>
                
                <div class="row mt-4 pb-4">
                  <div class="col-xs-8 col-4 col-lg-4" style="">
                    <p class="mb-1 small">Boletos:</p>
                    <h6 class="d-inline-flex bg-primary text-light rounded small py-1 px-2">{{ value1.totalBills }}</h6>
                  </div>
                  <div class="col-xs-8 pl-0 col-8 col-lg-8" style="">
                    <p class="mb-1 small">Valor total:</p>
                    <h6 class="small">{{  parseFloat(value1.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h6>
                  </div>
                  <div class="col-xs-8 col-12 d-flex flex-column col-lg-12 pt-2" style="">
                    <button class="btn btn-primary mt-3" @click="verBoletoDetalhado(value1, value1.contract)">Ver boletos</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
      <div class="row" style="">
      </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue, 
  formatData,
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
export default { 
  name: "MeusBoletos", 
  components: {
    ProgressLoading,
  },
  data() {
    return {
      links: [],
      cancelled:false,
      loadBoletos: false,
      boletos: null, 
      agreements: [],
      agreementsOne: [],
      contracts: [],
      contractsCetelem: [],
      contractClosed: [],
      largura: null,
      responseFromAgreement: {},
      totalBillsOneByContract: 0,
      hasBills: false,
      ownerId: null,
      userAdm: {},
      ipUser: null

    };
  },
 
  async created() {
      this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
      this.ipUser = localStorage.getItem('ipUser');
      this.largura = window.screen.width;
      this.loadBoletos = true;
      //eslint-disable-next-line
      // debugger;

      this.contractClosed = JSON.parse(localStorage.getItem("contractClosed"))
      console.log(this.contractClosed)

      if (!localStorage.getItem("contracts") || localStorage.getItem("contracts") === null || 
      typeof localStorage.getItem("contracts") === "undefined" || localStorage.getItem("contracts") === "undefined") {
        this.contracts = [];
      } else {
        this.contracts = JSON.parse(localStorage.getItem("contracts"));
      }

        if(this.contracts) {
        this.hasBills = false;
        this.contracts.map(element => {
          if(element.agreements.length > 0 || element.agreementsOne.length > 0) {
            this.hasBills = true;
            this.ownerId = element.OwnerID;
          }
        });
    }

    if (!localStorage.getItem("contractsCetelem") || localStorage.getItem("contractsCetelem") === null || 
      typeof localStorage.getItem("contractsCetelem") === "undefined" || localStorage.getItem("contractsCetelem") === "undefined") {
        this.contractsCetelem = [];
      } else {
        this.contractsCetelem = JSON.parse(localStorage.getItem("contractsCetelem"));
      }

        if(this.contractsCetelem) {
        this.hasBills = false;
        this.contractsCetelem.map(element => {
          if(element.agreements.length > 0 || element.agreementsOne.length > 0) {
            this.hasBills = true;
            this.ownerId = element.OwnerID;
          }
        });
    }

        var currentContract = null;
        var total = 0;

        this.contracts.map((contract) => {
          contract.agreementsOne.map((value) => {
          currentContract = value.contrato;
          if(currentContract === value.contrato) {
            total += parseFloat(value.valor.replace(",", "."), 10)
          }
        })
        })
        this.totalBillsOneByContract = total.toLocaleString("pt-BR", {currency: "BRL",minimumFractionDigits: 2,});
      
      this.contracts.map((contract) => {
        if(contract.agreementsOne.length > 0) {
        this.agreementsOne = contract.agreementsOne.reduce(function(reduced, value) {
        const index = reduced.findIndex((element) => element.contrato === value.contrato);
          if (index > -1) {
            reduced[index].total += parseFloat(value.valor.replace(",", "."), 10);
            reduced[index].totalBills += 1;
            return reduced;
          }
          reduced.push({ contrato: value.contrato, total: parseFloat(value.valor.replace(",", "."), 10), totalBills: 1, contract });
          return reduced;
        }, []);
        
        }
      })

      await this.ActionInsertLogByValueId({
        DocNumber: this.user.DocNumber,
        ValueID: 199,
        httpOwnerId: this.ownerId,
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        OS: navigator.platform,
        IP: this.ipUser ? this.ipUser : '',
      });

      if(this.contractClosed){
        this.hasBills = true;
      }
      
      this.loadBoletos = false;

  }, 
  computed: { 
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,
    ...mapActions("meusBoletos", [
      "ActionSendEmailOrSmsText",
      "ActionSendAccessLog",
      "ActionCancelAgreement",
      "ActionListAgreements",
    ]),
    ...mapActions("minhasDividas", [
      "ActionAcceptAgreement"
    ]),
    // ...mapActions('meusAcordos', 
    // ['ActionInsertLog'
    // ]),
    ...mapActions("meusContratos", ["ActionInsertLogByValueId"]),
    voltar() {
      this.$router.push({ name: "dashboard" });
    },

    sumByContract(value) {
      var total = 0;
      
        value.ParcelasAcordo.ParcelaAcordo.map((installment) => {
          total += parseFloat(installment.ValorParcela.replace(",", "."), 10)
        });

        return total.toLocaleString("pt-BR", {currency: "BRL",minimumFractionDigits: 2,});


      // let installmentTotal = 0
      // value.ParcelasAcordo.ParcelaAcordo.map((installment) => {
      //   installmentTotal += installmentTotal + parseFloat(installment.ValorParcela.replace(',', '.'), 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      // })
      // return installmentTotal
    },


    sumTotalInstallments() { 
      let totalInstallments = 0;
        this.agreements.map((value) => {
          value.ParcelasAcordo.map(() => {  
            totalInstallments += 1
          });
        }); 

        return totalInstallments; 
    }, 


    async verBoletoDetalhado(value, contract) {
      this.loadBoletos = true; 

      if(value.OwnerID == "34"){
        try { 
          localStorage.setItem("boletoSelected", JSON.stringify(this.contractClosed));
          localStorage.setItem("OwnerID", JSON.stringify(this.contractClosed[0].OwnerID));
          this.$router.push({  
            name: 'boletoAcordo',
          });
      } catch (error) {
        this.flashMessage.show({
          timeout:300,
          status: 'error',
          position:'top right', 
          title: 'Erro', 
          message: 'Tente novamente mais tarde!'});
        this.loadBoletos = false;
      }

      }else{
        try { 
            localStorage.setItem("boletoSelected", JSON.stringify(value));
            localStorage.setItem("OwnerID", JSON.stringify(contract.OwnerID));
            localStorage.setItem("info", JSON.stringify(contract.info));
            this.$router.push({  
              name: 'boletoAcordo',
            });
        } catch (error) {
          this.flashMessage.show({
            timeout:300,
            status: 'error',
            position:'top right', 
            title: 'Erro', 
            message: 'Tente novamente mais tarde!'});
          this.loadBoletos = false;
        }
    }
    },

        verBoletos() {
          return;
        },

        contractsPending() {
          this.$router.push({
            name: "meusContratos"
          });
        },

        cadastro() {
          this.$router.push({
            name: "cadastro",
            params: { agreements: this.contracts.agreements, contracts: this.contracts }
          });
        },

    async loadBill(){
      this.loadBoletos = true;
      const responseBoleto = await this.ActionListAgreements();

      this.boletos = responseBoleto;
      this.loadBoletos = false;
    },

    async cancelAgreement(agreement_id){
        this.loadBoletos = true;
        try{
            let response = await this.ActionCancelAgreement(agreement_id);
            if (!response.error) {
              this.cancelled = true;

              this.flashMessage.show({
                timeout: 1000,
                blockClass: "custom-block-class",
                status: "success",
                title: "Sucesso",
                message: "Cancelamento de acordo efetuado com sucesso",
              });
            } else {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                blockClass: "custom-block-class",
                title: "Erro",
                message:
                  response.message,
              });
            }

            this.loadBoletos = false;
        }catch(e){
           this.loadBoletos = false;

           this.flashMessage.show({
              timeout: 1000,
              status: "error",
              blockClass: "custom-block-class",
              title: "Erro",
              message:'não foi possível cancelar este boleto através dese canal!',
            });
        }

       
    },

    async enviarComprovante(linhaDigitavel) {
      this.$router.push({
        name: "comprovante",
        params: { linhadigitavel: linhaDigitavel },
      });
    },
    getBillValue(offers) {
      return offers;
    },
    async sendSms(linhaDigitavel) {
      if (this.user.MobileNumber == "" || this.user.MobileNumber == null) {
        this.flashMessage.show({
          timeout: 1000,
          blockClass: "custom-block-class",
          status: "error",
          title: "Erro",
          message:
            "Você não possui um número de celular cadastrado no sistema, por favor atualize seus dados",
        });
        this.goToUpdateInfo(); 
      } else {
        this.loadBoletos = true;

        let response = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: linhaDigitavel,
          Email: "",
          PhoneNumber: this.user.PhoneNumber,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });
        this.loadBoletos = false;

        if (!response.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            status: "success",
            title: "Sucesso",
            blockClass: "custom-block-class",
            message: "Linha digitável enviado por sms com sucesso",
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "error",
            title: "Erro",
            message:
              "Ocorreu um erro ao tentar enviar o SMS, tente novamente mais tarde",
          });
        }
      }
    },
    async sendEmail(linhaDigitavel) {
      if (this.user.Email == "" || this.user.Email == null) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Erro",
          blockClass: "custom-block-class",
          message:
            "Você não possui um e-mail cadastrado no sistema, por favor atualize seus dados",
        });
        this.goToUpdateInfo();
      } else {
        this.loadBoletos = true;

        let response = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: linhaDigitavel,
          Email: this.user.Email,
          PhoneNumber: "",
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        this.loadBoletos = false
        if (!response.data.error) {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por e-mail com sucesso",
          });
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "error",
            title: "Erro",
            message:
              "Ocorreu um erro ao tentar enviar o e-mail, tente novamente mais tarde",
          });
        }
      }
    },

    async copiarLinhaDigitavel(linhaDigitavel) {
      const elem = document.createElement("textarea");
      elem.value = linhaDigitavel;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.loadBoletos = true;

      await this.ActionSendAccessLog({
        ValueId: 11,
        LinhaDigitavel: linhaDigitavel,
      });

      this.loadBoletos = false;

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        position: "top right",
        blockClass: "custom-block-class",
        message: "Linha digitável foi copiada para o seu clipboard",
      });
    },

    async aceitarAcordo(indexBoleto, indexDeal, value, bill) {
      this.loadBoletos = true;

      let debtsData = value.debtsData;
      let contract = debtsData.contract;

      let result = await this.ActionAcceptAgreement({
        EnvironmentId: 105,
        ID: contract.id,
        ContractNumber: value.contractNumber,
        OriginalValue: value.value,
        UpdatedValue: value.updatedValue,
        DelayDays: contract.delayDays,
        DueDate: bill.dueDate,
        TotalValue: contract.totalValue,
        RunId: contract.runId,
        ProductCode: contract.productCode,
        Selected: contract.selected,
        Installments: contract.installments,
        SimulationId: bill.simulationId,
        DigestId: bill.digestId,
        AgreementType: bill.agreementType,
        NegotiationType: contract.negotiationType,
        DocumentId: value.documentId,
        OS: navigator.platform,
        IP: this.ipUser ? this.ipUser : '',
      });
      this.boletos[indexBoleto].deals[indexDeal].linhaDigitavel = result.results.digitableLine;

      this.loadBoletos = false;
    },
  },
};
</script>
<style scoped>

</style>